/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBpYGlL_loEWcl-kx_-Ina8aXKNsqLZ6jg",
  "appId": "1:629569315602:web:416d4304a54a2e6da189cb",
  "authDomain": "schooldog-i-troup-ga.firebaseapp.com",
  "measurementId": "G-QP8ZN7ZBXT",
  "messagingSenderId": "629569315602",
  "project": "schooldog-i-troup-ga",
  "projectId": "schooldog-i-troup-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-troup-ga.appspot.com"
}
